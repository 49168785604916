import './App.css';
import { useRef } from 'react';
import { useState } from 'react';
function App() {
  let inputTextRef = useRef(null);
  const [info, setInfo] = useState({name: "", age: 0, warrant: false, crimes: []})
  let possibleCrimes = [
    "Homicide", "Manslaughter", "Assault", "Battery", "Robbery", "Burglary", "Larceny",
    "Theft", "Arson", "Kidnapping", "Carjacking", "Sexual Assault", "Rape", "Child Abuse",
    "Child Endangerment", "Domestic Violence", "Stalking", "Harassment", "Identity Theft",
    "Fraud", "Embezzlement", "Forgery", "Counterfeiting", "Drug Possession", "Drug Trafficking",
    "Driving Under the Influence (DUI)", "Driving While Intoxicated (DWI)", "Public Intoxication",
    "Disorderly Conduct", "Trespassing", "Vandalism", "White-Collar Crimes", "Computer Crimes",
    "Cyberbullying", "Hate Crimes", "Money Laundering", "Racketeering", "Conspiracy", "Terrorism",
    "Public Corruption", "Environmental Crimes", "Weapons Offenses", "Illegal Gambling",
    "Prostitution", "Solicitation", "Human Trafficking", "Animal Cruelty", "DUI Manslaughter",
    "Aggravated Assault", "Aggravated Battery", "Armed Robbery", "Grand Theft Auto", "Forgery",
    "Wire Fraud", "Mail Fraud", "Insurance Fraud", "Tax Evasion", "Credit Card Fraud", "Bribery",
    "Perjury", "Obstruction of Justice", "Resisting Arrest", "RICO Violations", "Racketeering",
    "Money Laundering", "Extortion", "Blackmail", "Harassment", "Aggravated Stalking",
    "Cyberstalking", "Child Pornography", "Indecent Exposure", "Criminal Mischief", "Joyriding",
    "Hit and Run", "False Imprisonment", "Endangering the Welfare of a Child", "Unlawful Assembly",
    "Rioting", "Conspiracy to Commit a Crime", "Accessory to a Crime", "Obstruction of Justice",
    "Perjury", "Unauthorized Use of a Vehicle", "Possession of Stolen Property",
    "Receiving Stolen Property", "Criminal Trespass", "Criminal Mischief", "Aggravated Harassment",
    "Violation of a Restraining Order", "Unauthorized Access to Computer Systems",
    "Distribution of Child Pornography", "Cyber Espionage", "Criminal Threats", "Terroristic Threats",
    "Harassment", "Menacing", "Stalking", "Failure to Register as a Sex Offender", "Harboring a Fugitive",
    "Aiding and Abetting", "Concealing Evidence", "Accessory After the Fact", "Unlawful Discharge of a Firearm",
    "Illegal Possession of Firearms", "Assault with a Deadly Weapon", "Criminal Negligence", "Reckless Endangerment",
    "Aggravated Kidnapping", "False Imprisonment", "Aggravated Burglary", "Criminal Mischief", "Malicious Mischief",
    "Vehicular Manslaughter", "Reckless Driving", "Hit and Run", "Evading Arrest", "Conspiracy to Commit Fraud",
    "Wiretapping", "Illegal Wire Transfer", "Insurance Fraud", "Medicare/Medicaid Fraud", "Securities Fraud",
    "Insider Trading", "Money Laundering", "Mail Fraud", "Wire Fraud", "Conspiracy to Commit Cybercrime",
    "Unauthorized Access to Computer Systems", "Computer Hacking", "Denial-of-Service Attacks",
    "Distribution of Malicious Software", "Unauthorized Interception of Communications", "Child Grooming",
    "Child Enticement", "Luring a Minor", "Online Solicitation of a Minor", "Child Exploitation",
    "Child Endangerment", "Possession of Child Pornography", "Human Trafficking", "Forced Labor", "Sex Trafficking",
    "Drug Possession", "Drug Trafficking", "Manufacturing Drugs", "Prescription Drug Fraud",
    "Possession of Drug Paraphernalia", "Driving Under the Influence (DUI)", "Driving While Intoxicated (DWI)",
    "Reckless Driving", "Hit and Run", "Fleeing and Eluding", "Public Intoxication", "Disorderly Conduct",
    "Resisting Arrest", "Driving with a Suspended License", "Unauthorized Use of a Vehicle", "Grand Theft Auto",
    "Reckless Endangerment", "Illegal Racing", "Boating Under the Influence (BUI)", "Underage Drinking",
    "Contributing to the Delinquency of a Minor", "Possession of a Fake ID", "Public Urination",
    "Indecent Exposure", "Disorderly Conduct", "Disturbing the Peace", "Obstruction of Justice", "Resisting Arrest",
    "False Reporting", "Falsifying Evidence", "Tampering with Evidence", "Obstruction of a Criminal Investigation",
    "Perjury", "Contempt of Court", "Escape", "Bail Jumping", "Forgery", "Uttering Forged Instruments",
    "False Pretenses", "Embezzlement", "Bribery", "Extortion", "Blackmail", "Perjury", "Obstruction of Justice",
    "False Reporting", "False Identification", "Resisting Arrest", "Disorderly Conduct",
    "Obstruction of Government Operations", "Trespassing", "Vandalism", "Criminal Mischief", "Public Nuisance",
    "Rioting", "Affray", "Unlawful Assembly", "Disorderly Conduct", "Disturbing the Peace"]
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
    }; 
  function onSearch() {
    let hasWarrant = false
    let age = randomNumberInRange(16, 90)
    let newSuspectsCrimes = [];
    for (let i = 0; i < randomNumberInRange(0, 10); i++) {
      if (randomNumberInRange(0, 2) == 1) {
        newSuspectsCrimes.push({name: possibleCrimes[randomNumberInRange(0, possibleCrimes.length)], count: randomNumberInRange(1, 6)})
      }
    }
    if (newSuspectsCrimes.length > 1 && randomNumberInRange(1, 2) == 2) {
      hasWarrant = true
    }
    setInfo({name: inputTextRef.current.value, age: age, warrant: hasWarrant, crimes: newSuspectsCrimes })
    console.log(newSuspectsCrimes)
  }

  return (
    <div className="App">
      <div className='flex justify-center'>
        <div className='inline text-center'>
          <h1 className='Header-Text text-text text-5xl mt-24'>Belasco County MDT</h1>
          <input className='mt-10 w-full text-3xl rounded-md p-2 text-center text-white bg-[#36dfee] border-[#32746D] border-2 bg-opacity-20'
            ref={inputTextRef}/>
          <button className='mt-5 text-2xl bg-[#104F55] rounded-md w-28 text-text' onClick={onSearch}>Search</button>
        </div>
      </div>
      {info.name !== "" &&
        <div className='w-full flex justify-center mt-10'>
          <div className='bg-[#1b6066] w-1/4 rounded-2xl p-6'>
            <div className='inline justify-center text-text text-center'>
              {info &&
                <>
                  <h1 className='text-3xl mb-6'>Name: <span className=' bg-[#3c3c3c] text-white px-3 rounded-md'>{info.name}</span></h1>
                  <h1 className='text-3xl mb-6'>Age: <span className=' bg-[#3c3c3c] text-white px-3 rounded-md'>{info.age}</span></h1>
                  <h1>
                    <span className={  info.warrant ? "px-10 rounded-md text-3xl m- text-white bg-red-600" : "m-2 p-0.5 text-3xl text-text"}>Warrant: {info.warrant ? "Yes" : "No"}</span>
                  </h1>
                  <h1 className='text-3xl mb-1 mt-1'>Prior Infractions:</h1>
                  <ul className='text-2xl mb-6 bg-[#3c3c3c] text-white px-3 rounded-md'>
                    {info.crimes.map(function (crime, i) {
                      return <li key={i}>{crime.count}x {crime.name}</li>;
                    })}
                  </ul>
                  {info.crimes.length > 1 && randomNumberInRange(0, 2) === 2 || (info.crimes.length > 2) &&
                    <h1 className='text-xl text-white'> <span className=' bg-red-600 px-9 py-2 rounded-xl'>ARMED AND DANGEROUS ALERT</span></h1>
                  }
                </>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
